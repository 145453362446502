@import '../scss/variables';

body.contact {
  #logo img {
    filter: invert(80%);
  }
  #navBarToggle {
    filter: invert(80%);
  }
  footer {
    background-color: $dark;
    color: $white;
    a {
      color: $white;
    }
    #contactBottom {
      &::before {
        background-color: $white;
      }
    }
  }
}