body {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 400 1rem/1.2 $font;
  color: $dark;
}

section {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4 {
  display: block;
  margin: 0;
  padding: 0;
  font: 400 3rem/1 $heading;
}

button {
  border: none;
  padding: 0;
  background: transparent;
  @media #{$desktop} {
    cursor: pointer;
  }
}

.more {
  display: inline-block;
  padding: 15px 35px;
  margin: 30px 0;
  max-width: max-content;
  color: $dark;
  font: 200 1.5rem/1 $font;
  border-radius: 10px;
  border-left: 1px solid rgba($dark, .3);
  border-right: 1px solid rgba($dark, .3);
  transition: all 0.5s;
  @media #{$desktop} {
    &:hover {
      box-shadow: 0 0 15px rgba($dark, .1);
    }
  }
}

.noScroll {
  overflow-y: hidden;
}

#logo {
  display: block;
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 10;
  @media #{$smallMobile} {
    left: 30px;
    top: 30px;
  }
  @media #{$small400} {
    right: 30px;
    img {
      width: 100%;
      height: auto;
    }
  }
}
#logo.menuOpen {
  img {
    filter: invert(80%);
  }
}

#mainNav {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100svh;
  z-index: 9;
  background-color: $dark;
  overflow-y: scroll;
  #nav {
    width: 65%;
    height: 100vh;
    height: 100svh;
    @media #{$mobile} {
      width: 100%;
    }
    ul {
      position: absolute;
      top: 250px;
      left: 100px;
      @media #{$smallDesktop} {
        top: 180px;
      }
      @media #{$mobile} {
        left: 50%;
        transform: translateX(-50%);
      }
      li {
        @media #{$mobile} {
          text-align: center;
        }
        a {
          display: block;
          font: 100 5.5vw/6.5vw $font;
          color: $accent;
          text-transform: uppercase;
          @media #{$smallDesktop} {
            font-size: 5vw;
            line-height: 6vw;
          }
          @media #{$mobile} {
            font-size: 9vh;
            line-height: 11vh;  
          }
          @media #{$smallMobile} {
            font-size: 12vw;
            line-height: 11vh;  
          }
        }
      }
    }
  }
  #blogNews {
    background-color: $accent;
    width: calc(35% - 150px);
    height: calc(100vh - 350px);
    height: calc(100svh - 350px);
    padding: 250px 50px 100px 100px;
    @media #{$smallDesktop} {
      padding-top: 220px;
      height: calc(100vh - 320px);
      height: calc(100svh - 320px);
    }
    @media #{$mobile} {
      display: none;
    }
    h3 {
      font: 400 3rem/1 $heading;
      margin-bottom: 50px;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      gap: 20px;
      &::before {
        content: '';
        width: 50px;
        height: 1px;
        background-color: $dark;
      }
      a {
        display: block;
        width: 100%;
        font-size: 1.5rem;
        line-height: 30px;
        font-weight: 200;
        color: $dark;
      }
    }
  }
}
#mainNav.open {
  display: flex;
}

#navBarToggle {
  background: url('../img/toggleMenuBg.svg') 1px 0 no-repeat;
  background-size: 163.2px;
  position: fixed;
  top: 0;
  right: 0;
  display: block;
  width: 163.2px;
  height: 135px;
  z-index: 11;
  transition: all .5s;
  @media #{$smallMobile} {
    background-position: 30px -30px;
  }
  #toggle {
    position: absolute;
    top: 55px;
    right: 50px;
    @media #{$smallMobile} {
      top: 30px;
      right: 30px;
    }
    .line {
      display: block;
      margin: 8px 0;
      background-color: $dark;
      width: 70px;
      height: 2px;
      border-radius: 2px;
    }
  }
}
#navBarToggle.close {
  filter: invert(90%);
  @media #{$mobile} {
    filter: none;
  }
  #toggle {
    top: 60px;
    width: 70px;
    height: 70px;
    @media #{$smallMobile} {
    top: 30px;
    width: 50px;
    height: 50px;
    }
    .line {
      position: absolute;
      width: 70px;
      transform: rotate(-45deg);
      @media #{$smallMobile} {
        width: 50px;
      }
    }
    .line:first-of-type {
      transform: rotate(45deg);
    }
  }
}

.offer {
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    @media #{$smallDesktop} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media #{$smallMobile} {
      grid-template-columns: repeat(1, 1fr);
    }
    li {
      text-align: center;
      h3 {
        font-size: 1.4rem;
        font-weight: 700;
        margin: 10px 0;
      }
      p {
        margin: 0 0 10px 0;
      }
      .price {
        background-color: $accent;
        padding: 5px 15px;
        border-radius: 50px;
        font-size: 1.5rem;
        font-weight: 300;
        color: $dark;
        max-width: max-content;
        margin: 0 auto;
        span {
          display: block;
          font-size: 0.7rem;
          font-weight: 400;
        }
      }
    }
  }
}

#articles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  @media #{$smallDesktop} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media #{$smallMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
  .item {
    position: relative;
    img {
      max-width: 100%;
      height: auto;
    }
    a {
      display: block;
      margin: 30px 0 20px 0;
      font: 400 2rem/1.1 $heading;
      color: $dark;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }
}

footer {
  width: 100%;
  height: 100vh;
  height: 100svh;
  background-color: $accent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media #{$mobile} {
    align-items: flex-start;
    padding: 50px;
    width: calc(100% - 100px);
    height: calc(100vh - 100px);
    height: calc(100svh - 100px);
  }
  @media #{$smallMobile} {
    padding: 30px;
    width: calc(100% - 60px);
    height: calc(100vh - 60px);
    height: calc(100svh - 60px);
  }
  h2 {
    display: block;
    margin: 0 0 50px 0;
    &::after {
      display: none;
    }
    @media #{$mobile} {
      margin: 0;
    }
  }
  a {
    color: $dark;
  }
  #contactBottom {
    position: relative;
    height: 60%;
    @media #{$smallDesktop} {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
    }
    @media #{$smallMobile} {
      height: 30%;
    }
    &::before {
      content: '';
      display: block;
      width: 0.4px;
      height: 100%;
      background-color: $dark;
      @media #{$smallDesktop} {
        display: none;
      }
    }
    a {
      font-size: 3rem;
      font-weight: 100;
      white-space: nowrap;
      @media #{$smallDesktop} {
        font-size: 7vw;
        font-weight: 300;
      }
    }
    p {
      margin: 0;
    }
    #phone, #mail {
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      @media #{$smallDesktop} {
        position: relative;
        top: auto;
        left: auto;
      }
    }
    #phone {
      transform: translateX(-440px);
      @media #{$smallDesktop} {
        transform: none;
      }
    }
    #mail {
      transform: translateX(100px);
      @media #{$smallDesktop} {
        transform: none;
      }
    }
  }
  #copyright, #createBy {
    position: absolute;
    bottom: 50px;
    @media #{$smallMobile} {
      bottom: 30px;
    }
  }
  #copyright {
    font: 200 1rem/1.5rem $font;
    display: block;
    width: 60%;
    margin: 0;
    left: 50px;
    @media #{$smallMobile} {
      left: 30px;
    }
    a {
      text-decoration: underline;
    }
  }
  #createBy {
    right: 50px;
    @media #{$smallMobile} {
      right: 30px;
      bottom: 20px;
    }
  }
}