@import '../scss/variables';

article {
  padding: 250px 50px 50px;
  @media #{$mobile} {
    padding: 220px 30px 30px;
  }
  .flex {
    display: flex;
    gap: 50px;
    @media #{$mobile} {
      flex-direction: column;
    }
  }
  #content {
    width: 50%;
    @media #{$smallDesktop} {
      width: 60%;
    }
    @media #{$mobile} {
      width: 100%;
    }
    h1 {
      margin-bottom: 50px;
    }
    h2 {
      font-size: 2.2rem;
      margin-bottom: 20px;
    }
    p {
      font: 200 1.5rem/2rem $font;
      margin-bottom: 30px;
      a {
        color: $dark;
        background-color: $accent;
        padding: 0 5px;
      }
    }
    ul {
      margin: 30px 0;
      list-style: circle;
      li {
        font: 200 1.5rem/2rem $font;
        margin-left: 50px;
      }
    }
  }
  .offer {
    width: 50%;
    margin-bottom: 40px;
    @media #{$mobile} {
      width: 100%;
    }
  }
  #ariclePhoto {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 80%;
      @media #{$smallDesktop} {
        height: 50%;
        object-position: 50%;
      }
      @media #{$small400} {
        height: 400px;
      }
    }
  }
}

body.single {
  #heroSection {
    h1 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      font: italic 200 6rem/1 $font;
      text-transform: uppercase;
      background-color: rgba($accent, .8);
      padding: 50px 100px 150px 50px;
      border-radius: 15% 35% 75% 0;
      @media #{$smallDesktop} {
        bottom: 0;
        font-size: 3rem;
        font-weight: 300;
        padding-bottom: 100px;
      }
      @media #{$mobile} {
        width: calc(100% - 150px);
        font-size: 8vw;
      }
      @media #{$smallMobile} {
        width: calc(100% - 80px);
        font-size: 10vw;
        padding: 30px 50px 60px 30px;
      }
    }
    img {
      width: 100vw;
      height: 100vh;
      height: 100svh;
      object-fit: cover;
      object-position: 10%;
      @media #{$mobile} {
        object-position: 50%;
      }
      @media #{$smallMobile} {
        object-position: 60%;
      }
    }
  }
  article {
    padding: 50px;
    @media #{$mobile} {
      padding: 30px;
    }
    #content {
      width: 70%;
      margin: 0 auto;
      @media #{$mobile} {
        width: 100%;
      }
    }
  }
}