@import './variables';

body.home {
  #heroSection {
    background: url("../../public/img/heroBg.jpg") center bottom no-repeat;
    background-size: cover;
    height: 100vh;
    height: 100svh;
    @media #{$smallMobile} {
      background-position: 85% bottom;
    }
    h1 {
      position: absolute;
      left: 100px;
      bottom: 100px;
      font: italic 100 8vw/1 $font;
      text-transform: uppercase;
      @media #{$smallDesktop} {
        bottom: 70px;
        font-size: 7vw;
      }
      @media #{$mobile} {
        bottom: 70px;
        font-size: 10vw;
      }
      @media #{$smallMobile} {
        font-size: 20vw;
        left: 30px;
        font-weight: 300;
      }
      .subTitle {
        font: normal 400 2rem/1 $heading;
        text-transform: none;
        display: flex;
        justify-content: flex-end;
        background-color: $accent;
        padding: 10px;
        margin-top: -20px;
        @media #{$smallMobile} {
          font-size: 6vw;
          margin-top: -15px;
        }
      }
    }
  }

  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 100px);
    padding: 50px;
    @media #{$smallMobile} {
      width: calc(100% - 60px);
      padding: 30px;
    }
  }
  h2 {
    font-size: 4.375rem;
    margin: 50px 50px 50px 0;
    display: flex;
    align-items: center;
    gap: 20px;
    white-space: nowrap;
    @media #{$mobile} {
      margin: 0 0 30px 0;
      font-size: 8vw;
      white-space: normal;
    }
    @media #{$smallMobile} {
      font-size: 12vw;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 0.4px;
      background-color: $dark;

    }
  }
  .image {
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 80%;
      @media #{$smallDesktop} {
        object-position: 70%;
      }
      @media #{$mobile} {
        height: auto;
      }
    }
  }

  section {
    height: 100%;
  }
  .more {
    margin-top: 50px;
    @media #{$mobile} {
      margin: 10px 0 0 0;
    }
  }

  #aboutMe {
    display: flex;
    gap: 30px;
    @media #{$mobile} {
      flex-direction: column;
      gap: 0;
    }
    .desc {
      p {
        margin: 10px 0 20px 0;
        font: 200 1.8rem/2.6rem $font;
        @media #{$smallMobile} {
          font-size: 1.6rem;
          line-height: 2.2rem;
        }
        a {
          color: $dark;
          background-color: $accent;
          padding: 0 5px;
        }
      }
      .greetings {
        font-style: italic;
      }
      h2 {
        margin: 0 0 20px 0;
      }
    }
    .image {
      width: auto;
    }
  }

  #woman {
    @media #{$mobile} {
      flex-direction: column-reverse;
    }
  }
  #man {
    @media #{$mobile} {
      flex-direction: column;
    }
    .desc {
      h2 {
        margin-right: 0;
      }
    }
  }
  #woman, #man {
    display: flex;
    .image {
      width: 100%;

    }
    .desc {
      .offer {
        width: 80%;
        margin: 0 auto;
        @media #{$mobile} {
          width: 100%;
        }
      }
    }
    @media #{$mobile} {
      .more {
        margin-top: 30px;
      }
    }
  }

  #blogSec {
    width: calc(100% - 100px);
    height: auto;
    padding: 100px 50px;
    @media #{$mobile} {
      padding: 50px;
    }
    @media #{$smallMobile} {
      width: calc(100% - 60px);
      padding: 30px;
    }
    h2 {
      width: 100%;
      &::after {
        display: none;
      }
    }
  }

  @media #{$smallMobile} {

    .image {
      img {
        height: 70svh;
      }
    }
  }
}