$font: 'Work Sans', sans-serif;
$heading: 'Forum', serif;

$white: #fafafa;
$dark: #2B2B2B;
$accent: #ECF0EA;

$filterWhite: invert(100%) sepia(11%) saturate(454%) hue-rotate(267deg) brightness(117%) contrast(96%);
$filterDark: invert(12%) sepia(0%) saturate(323%) hue-rotate(166deg) brightness(94%) contrast(86%);
$filterAccent: invert(100%) sepia(29%) saturate(131%) hue-rotate(37deg) brightness(98%) contrast(91%);

$small400: "(max-width: 400px)";
$smallMobile: "(max-width: 600px)";
$mobile: "(max-width: 991px)";
$smallDesktop: "(max-width: 1366px)";
$lowHeight: "(max-height: 828px)";
$desktop: "(min-width: 1366px)";