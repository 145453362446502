@import '../scss/variables';
body.blog {
  h1 {
    margin-bottom: 50px;
  }
  #articles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    @media #{$smallDesktop} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media #{$smallMobile} {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}